import React from 'react'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import WebIcon from '@material-ui/icons/Web'
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices'
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols'

const Cards = () => {
    return (
        <section className="introduction">
            <div className="cont">
                <div className="main">
                    <div className="introMain">
                        <div className="icon">
                            <SupervisorAccountIcon className="iconsYellow"/>
                        </div>
                        <div className="introText">
                            <h3>Multiple profile logins</h3>
                            <p>Create and manage multiple browser profiles on websites. Each browser profile is located separately in the cloud, guaranteeing the protection of your data and web anonymity.</p>
                        </div>
                    </div>
                    <div className="introMain">
                        <div className="icon">
                            <EmojiSymbolsIcon className="iconsYellow"/>
                        </div>
                        <div className="introText">
                            <h3>Social Media Marketing</h3>
                            <p>Work in parallel with hundreds of social media profiles. Manage team members individual access rights to profile groups. </p>
                        </div>
                    </div>
                    <div className="introMain">
                        <div className="icon">
                            <WebIcon className="iconsYellow"/>
                        </div>
                        <div className="introText">
                            <h3>Multiple identity tabs</h3>
                            <p>We all get tired flipping through different screens and logging in/out from our multiple identity profiles. With Ricta Browser this problem is solved.</p>
                        </div>
                    </div>
                    <div className="introMain">
                        <div className="icon">
                            <ImportantDevicesIcon className="iconsYellow"/>
                        </div>
                        <div className="introText">
                            <h3>Support all platforms</h3>
                            <p>Regardless of what device you have, we’ve got you covered. Get Ricta Browser for Linux, macOS and Windows.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Cards