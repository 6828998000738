import React from 'react'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent'
import WorkIcon from '@material-ui/icons/Work'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import RictaImg from '../../images/rictaImg.jpg'
import BricoBrowser from '../../assets/bricoBrowser1line.svg'

const RictaBrowser = () => {
    return (
        <section className="intro">
            <div className="grid-container">
                <div className="grid-item">
                    <div className="flex justify-center my-2">
                        <BricoBrowser className="logoTitle"/>
                    </div>
                    <hr className="horizontalLine"/>
                    <div className="container">
                        <div className="left">
                            <EmojiObjectsIcon className="icons"/>
                        </div>
                        <div className="right">
                            <div>Do you listen to music or watch movie while browsing the web, replying to email and scroling your social media newsfeed?
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="left">
                            <WbIncandescentIcon className="icons"/>
                        </div>
                        <div className="right">
                            <div>BricoBrowser was built to help you!
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="left">
                            <WorkIcon className="icons"/>
                        </div>
                        <div className="right">
                            <div>We all get tired flipping through different screens and logging in/out from our multiple identity profiles. With BricoBrowser, social media-multitasking is made easy. 
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="left">
                            <DesktopWindowsIcon className="icons"/>
                        </div>
                        <div className="right">
                            <div>Build your workspace with all the browsing screens you needs and then enjoy simultaneous access to various feeds. All available with one click!</div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <img src={RictaImg}  alt="rictaImg"/>
                </div>
            </div>
        </section>
    )
}
export default RictaBrowser