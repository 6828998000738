import React from 'react'
import StarRateIcon from '@material-ui/icons/StarRate'
import Img03 from '../../images/pic03.jpg'
import Img04 from '../../images/pic04.jpg'
import Img05 from '../../images/pic05.jpg'

const Feedbacks = () => {
    return (
        <section className="team">
            <h3>Meet some of our satisfied customers</h3>
            <hr className="horizontalLine"/>
            <div className="teams">
                <div className="card">
                    <p><i>&quot;Awasome app. By organizing my work and personal screens, I don’t need to flit through several browsers anymore&quot;</i></p>
                    <div>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                    </div>
                    <div className="person">
                        <img src={Img03} alt="avatar"/>
                        <div className="personText">
                            <h4>Daniel U.</h4>
                            <h6>Work from home user</h6>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <p><i>&quot;Working with 3 email <br/>simultaneus identity logins is life changing browsing experience. Love it.&quot;</i></p>
                    <div>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                    </div>
                    <div className="person">
                        <img src={Img03}  alt="avatar"/>
                        <div className="personText">
                            <h4>Roberto M.</h4>
                            <h6>Virtual Assistent</h6>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <p><i>&quot;So easy to do my job when now I can create individual screen with various social media channels for each company I manage.&quot;</i></p>
                    <div>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                    </div>
                    <div className="person">
                        <img src={Img04}  alt="avatar"/>
                        <div className="personText">
                            <h4>Ana R.</h4>
                            <h6>Social Media Manager</h6>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <p><i>&quot;Accessing webpages without<br/> cenzorship allows me gather<br/> all the facts for my <br/>stories.&quot;</i></p>
                    <div>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                    </div>
                    <div className="person">
                        <img src={Img04} alt="avatar"/>
                        <div className="personText">
                            <h4>Martha C.</h4>
                            <h6>Journalist</h6>
                        </div>
                    </div>
                </div>        <div className="card">
                    <p><i>&quot;The app saves me hours of preparation of browsing environment. Professional display and flow.&quot;</i></p>
                    <div>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                    </div>
                    <div className="person">
                        <img src={Img05} alt="avatar"/>
                        <div className="personText">
                            <h4>Mateo D.</h4>
                            <h6>OSINT Analyst</h6>
                        </div>
                    </div>
                </div>        <div className="card">
                    <p><i>&quot;Minimal user effort<br/> required from the user. Fastest browsing speed from all the apps I tried.&quot;</i></p>
                    <div>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                        <StarRateIcon className="star"/>
                    </div>
                    <div className="person">
                        <img src={Img04} alt="avatar"/>
                        <div className="personText">
                            <h4>Simona T.</h4>
                            <h6>Due Diligence Analyst</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Feedbacks