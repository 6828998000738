import React, {useEffect,useState} from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SubscribeModal from '../components/modals/subscribeModal'
import RictaBrowser from '../components/homeComponents/rictaBrowser'
import Benefits from '../components/homeComponents/benefits'
import Products from '../components/homeComponents/products'
import Feedbacks from '../components/homeComponents/feedbacks'
import FlowCharts from '../components/homeComponents/flowCharts'
import DownloadContent from '../components/homeComponents/downloadContent'
import Introduction from '../components/homeComponents/introduction'
import Cards from '../components/homeComponents/cards'

const IndexPage = () => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false) 
    const modal = () => {
        setModalIsOpen(true)
    }

    useEffect(() => {
        setTimeout(modal,5000)
    },[])
    return (
        <div>
            <Layout>
                <Seo title="Home" description="Home page Ricta Browser" /> {/*svaku stranicu wrap u layout i seo i daj joj durgaciji naslov i opis*/}
                <Introduction setModalIsOpen={setModalIsOpen}/>
                <RictaBrowser/>
                <Benefits/>
                <Cards/>
                <Products/>
                <FlowCharts/>
                <Feedbacks/>
                <DownloadContent/>
                {modalIsOpen && <SubscribeModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}/>}
            </Layout>
        </div>
    )
}

export default IndexPage
