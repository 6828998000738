import React from 'react'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import ComputerIcon from '@material-ui/icons/Computer'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

const Benefits = () => {
    return (
        <section className="benefit">
            <h2>Top 3 benefits for You!</h2>
            <div className="cont">
            <hr className="horizontalLine"/>
            <div className="rictos">
                <div className="benefits">
                    <MultilineChartIcon className="icons"/>
                    <p>Eliminate media multi-tasking fatigue by creating your own visualization screens.</p>
                    <button><a href="#">Learn more</a></button>
                </div>
                <div className="benefits">
                    <ComputerIcon className="icons"/>
                    <p>Increase productivity by superior browsing session organization.</p>
                    <button><a href="#">Learn more</a></button>
                </div>
                <div className="benefits">
                    <NotificationsActiveIcon className="icons"/>
                    <p>Never miss a thing with <br/>notification manager.</p>
                    <button><a href="#">Learn more</a></button>
                </div>
            </div>
            </div>
        </section>
    )
}
export default Benefits