import React from 'react'

const DownloadContent = () => {
    return (
        <section className="download cont">
            <hgroup>
                <h2>Get Started Now!</h2>
                <h5>Become a multi-tasking browsing grand master !</h5>
                <p>Download the free community edition or our premium versions of the app. We are Windows, Mac, or Linux compatible.</p>
            </hgroup>
            <div className="divButton">
                <button className="p-2 my-5 mx-2 bg-yellowColor px-4 font-bold text-mainColor rounded border-2 border-transparent hover:border-mainColor shadow-md transition duration-500 md:text-xl">Download for Free</button>
            </div>
        </section>
    )
}
export default DownloadContent