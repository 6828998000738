import React from 'react'
import LaptopIcon from '@material-ui/icons/Laptop'
import WebIcon from '@material-ui/icons/Web'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import CodeIcon from '@material-ui/icons/Code'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import FunctionsIcon from '@material-ui/icons/Functions'
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

const Products = () => {
    return (
        <section className="product">
            <div className="containerDiv">
                <h2>Sneak peek…. Some of the awesome features and functionalities we are developing for your delight.</h2>
            </div>
            <div className="products">
                <div className="items">
                    <LaptopIcon className="icons"/>
                    <h4><a href="#">Build your own workspaces</a></h4>
                </div>
                <div className="items">
                    <WebIcon className="icons"/>
                    <h4><a href="#">Browse with <br/>Proxies</a></h4>
                </div>
                <div className="items">
                    <DesktopWindowsIcon className="icons"/>
                    <h4><a href="#">Customized vissulation screens</a></h4>
                </div>
                <div className="items">
                    <CodeIcon className="icons"/>
                    <h4><a href="#">Java script injection</a></h4>
                </div>
                <div className="items">
                    <NotificationsActiveIcon className="icons"/>
                    <h4><a href="#">Manage <br/>notfications</a></h4>
                </div>
                <div className="items">
                    <SpeakerNotesIcon className="icons"/>
                    <h4><a href="#">Manage Task <br/> Take Notes</a></h4>
                </div>
                <div className="items">
                    <MultilineChartIcon className="icons"/>
                    <h4><a href="#">Resurces utilization monotornig</a></h4>
                </div>
                <div className="items">
                    <FunctionsIcon className="icons"/>
                    <h4><a href="#">Hibernation function</a></h4>
                </div>
                <div className="items">
                    <SpellcheckIcon className="icons"/>
                    <h4><a href="#">Spell check; Ad Block functionality</a></h4>
                </div>
            </div>
        </section>
    )

}
export default Products