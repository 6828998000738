import React, { useState, useEffect } from 'react'
import  DiagramBig  from '../../assets/diagramLaptop.svg'
import  DiagramSmall  from '../../assets/diagramMobile.svg'
import useViewport from '../hooks/user-report'

const FlowCharts = () => {
    const { width } = useViewport() //current width of users screen
    const [ mobileSize, setMobileSize ] = useState(false)

    useEffect(() => {
        if (width < 767) {
            setMobileSize(true)
        }
        else {
            setMobileSize(false)
        }
    }, [width])
    
    return (            
        <section className="steps">
            <h3>Intuitive and stralghtfoward flow becuse we belive your should be able to user our application without reading the manual.</h3>
            { mobileSize ?
                <DiagramSmall className="w-11/12"/> :
                <DiagramBig className="w-full"/> }
        </section>
    )
}
export default FlowCharts