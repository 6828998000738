/* eslint-disable react/prop-types */
import React from 'react'
import BricoBrowser from '../../assets/logoWhiteSvg.svg'

const Introduction = ({setModalIsOpen}) => {
    return (
        <header className="animate-fade-in-up bg-no-repeat bg-center bg-cover h-screen resize">
            <div className="h-screen bg-opacity-50 bg-black flex items-center justify-center" style={{background:'rgba(0,0,0,0.5)'}}>
                <div className="mx-2 text-center">
                    <div className="flex justify-center m-2">
                        <BricoBrowser className="logoTitle"/>
                    </div>
                    <h1 className="animate-bounce mb-4 text-gray-200 font-extrabold text-lg md:text-4xl">
                        Become a multi-tasking browsing grand master !
                    </h1>

                    <h1 className="text-gray-100 mb-3 font-bold xs:text-lg md:text-2xl">
                    Join our community, get the latest updates on product development <br/> and access to amazing promotions by requesting your early invitation.<br/> We promise to never spam you!
                    </h1>
                    <h1 className="text-gray-100 mb-3 font-extrabold xs:text-lg md:text-2xl">
                        Comming soon!
                    </h1>
                    <div className="inline-flex">
                        <button onClick={()=>setModalIsOpen(true)} className="p-2 my-5 mx-2 bg-yellowColor px-4 font-bold text-mainColor rounded border-2 border-transparent hover:border-mainColor shadow-md transition duration-500 md:text-xl">Subscribe</button>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Introduction